import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo"

const TermsOfUsePage = (props) => {
    let title = "Terms of Use | Sacramento Web Design - Pyxel Development"
    let location = props.location.pathname
    return (
        <div>
            <Layout>
                <Seo title={title} location={location} />
                <div className="bg-offblue">
                    <div className="container mx-auto ">
                        <div className="flex justify-center lg:justify-end p-10 pt-36 xl:py-40">
                            <div>
                                <h1 className="text-6xl xl:text-7xl font-pixeboy text-white">
                                    Terms and Conditions
                                </h1>
                                <div className="mt-5 max-w-7xl mx-auto">
                                    <h2 className="font-normal py-5"><i>Effective date:</i> November 8, 2021</h2>
                                    <p className="mt-4 font-bold text-3xl mb-3">1.Introduction</p>
                                    <p className="mt-1">These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website
                                        (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full.
                                        You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions.</p>
                                    <p className="mt-4">This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor.</p>
                                    <p className="mt-4 font-bold text-3xl mb-3">2.Intellectual Property Rights</p>
                                    <p className="mt-1">Other than content you own, which you may have opted to include on this Website, under these Terms, Pyxel Development and/or its licensors own all rights to the intellectual property and material contained in this Website,
                                        and all such rights are reserved.</p>
                                    <p className="mt-4">You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.</p>
                                    <p className="mt-4 font-bold text-3xl mb-3">3.Restrictions</p>
                                    <p className="mb-4">You are expressly and emphatically restricted from all of the following:</p>
                                    <ul className="list-disc ml-10">
                                        <li>Publishing any Website material in any media</li>
                                        <li >Selling, sublicensing and/or otherwise commercializing any Website material</li>
                                        <li>Publicly performing and/or showing any Website material</li>
                                        <li>Using this Website in any way that is, or may be, damaging to this Website</li>
                                        <li>Using this Website in any way that impacts user access to this Website</li>
                                        <li>Using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity</li>
                                        <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website</li>
                                        <li>Using this Website to engage in any advertising or marketing</li>
                                    </ul>
                                    <p className="mt-4">Certain areas of this Website are restricted from access by you and Pyxel Development may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion.
                                        Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.</p>
                                    <p className="mt-4 font-bold text-3xl mb-3">4.Your Content</p>
                                    <p className="mt-1">In these Website Standard Terms And Conditions, “Your Content” shall mean any audio, video, text, images or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Pyxel Development a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>
                                    <p className="mt-4">Your Content must be your own and must not be infringing on any third party’s rights. Pyxel Development reserves the right to remove any of Your Content from this Website at any time, and for any reason, without notice.</p>

                                    <p className="mt-4 font-bold text-3xl mb-3">5.No warranties</p>
                                    <p className="mt-1">This Website is provided “as is,” with all faults, and Pyxel Development makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.</p>

                                    <p className="mt-4 font-bold text-3xl mb-3">6.Limitation of liability.</p>
                                    <p className="mt-1">In no event shall Pyxel Development, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Pyxel Development, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>

                                    <p className="mt-4 font-bold text-3xl mb-3">7.Indemnification</p>
                                    <p className="mt-1">IYou hereby indemnify to the fullest extent Pyxel Development from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.</p>

                                    <p className="mt-4 font-bold text-3xl mb-3">8.Severability</p>
                                    <p className="mt-1">If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</p>

                                    <p className="mt-4 font-bold text-3xl mb-3">9.Variation of Terms</p>
                                    <p className="mt-1">Pyxel Development is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.</p>

                                    <p className="mt-4 font-bold text-3xl mb-3">10. Assignment</p>
                                    <p className="mt-1">In these Website Standard Terms And Conditions, “Your Content” shall mean any audio, video, text, images or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Pyxel Development a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>

                                    <p className="mt-4 font-bold text-3xl mb-3">11.Entire Agreement</p>
                                    <p className="mt-1">These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Pyxel Development and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.</p>

                                    <p className="mt-4 font-bold text-3xl mb-3">12.Governing Law & Jurisdiction</p>
                                    <p className="mt-1">These Terms will be governed by and construed in accordance with the laws of the State of California, and you submit to the non-exclusive jurisdiction of the state and federal courts located in California for the resolution of any disputes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default TermsOfUsePage